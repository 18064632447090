import type { Stage } from '@/types/env-stage'
import type { LangType } from '@/types/i18n'
import PackageInfo from 'package.json'
import dynamic from 'next/dynamic'
import { useTranslation } from 'next-i18next'
import { useIntercomHider } from '@libs-components/hooks'
import { SHOP_PATH } from '@libs-components/utils/routes'
import { withFullShopsUrl } from '@/libs/routes'
import { AuthLogo } from '@/features/auth/auth-logo'
import { useAuth } from '@/features/auth'

const WuAuthForm = dynamic(
  import('@libs-components/components/auth-form').then(e => e.AuthForm),
  { ssr: false },
)

const { version } = PackageInfo

const LoginPage = () => {
  const { i18n } = useTranslation()

  const {
    authLayout,
    setAuthLayout,
    onError,
    onRequest,
    onSuccess,
    onForgetPasswordSuccess,
    onForgetPasswordError,
  } = useAuth()

  useIntercomHider({ shouldHideIntercom: true })

  return (
    <WuAuthForm
      logo={<AuthLogo />}
      lang={i18n.resolvedLanguage as LangType}
      stage={process.env.NEXT_PUBLIC_STAGE as Stage}
      apiDomain={process.env.NEXT_PUBLIC_API_DOMAIN as string}
      clientInfo={`shop/${version}`}
      layout={authLayout}
      setLayout={setAuthLayout}
      domain={process.env.NEXT_PUBLIC_ROOT_DOMAIN as string}
      googleClientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
      fbAppId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
      onRequest={onRequest}
      onSuccess={onSuccess}
      onError={onError}
      appleRedirectUri={process.env.NEXT_PUBLIC_SELF_DOMAIN}
      termUrl={withFullShopsUrl(SHOP_PATH.TERMS_OF_SERVICE)}
      forgetPasswordLink={`${process.env.NEXT_PUBLIC_FLASHCARD_WEB_DOMAIN}/auth/reset-password`}
      onforgetPasswordSuccess={onForgetPasswordSuccess}
      onforgetPasswordError={onForgetPasswordError}
    />
  )
}

export default LoginPage
